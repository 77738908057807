<template>
  <tr
    :class="{
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <th v-blokkli-editable:field_title>{{ title }}</th>
    <td>
      <div
        v-blokkli-editable:paragraphs_text
        class="content mt-0 mb-0"
        v-html="text"
      />
    </td>
  </tr>
</template>

<script lang="ts" setup>
const { options } = defineBlokkli({
  bundle: 'table_row',
  globalOptions: ['isHidden'],
})

defineProps<{
  title: string
  text: string
}>()
</script>
