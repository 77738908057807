<template>
  <div
    v-if="person || isEditing"
    class="paragraph paragraph-person-box md:flex max-w-prose"
    :class="{
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <div v-if="person && person.image" class="shrink-0 flex-1">
      <MediaImage v-bind="person.image" :image-style="imageStyle" />
    </div>
    <div v-if="person" class="flex-1">
      <div class="box-padding md:pt-0 mobile-only:pl-0">
        <h2
          class="font-semibold text-xl md:text-3xl lg:text-4xl mb-10 lg:mb-20"
        >
          {{ person.name }}
        </h2>
        <p v-if="person.position">{{ person.position }}</p>
        <div
          v-if="person.description"
          class="mt-10 lg:mt-20 content"
          v-html="person.description"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphPersonBoxFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphImage',
})

const { isEditing, options } = defineBlokkli({
  bundle: 'person_box',
  globalOptions: ['isHidden'],
})

defineProps<{
  person?: ParagraphPersonBoxFragment['person']
}>()

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    sm: 767,
    md: 364,
    lg: 323,
    xl: 385,
  },
})
</script>
