/**
 * Basic removal of HTML tags from a string.
 *
 * Note that this is NOT bulletproof and should only be used when it's okay if removal fails.
 */
export function stripTags(v: string): string {
  return v.replace(/(<([^>]+)>)/gi, '').replaceAll('&nbsp;', ' ')
}

export function convertToValidFileName(input: string): string {
  // Define characters that are not allowed in file names across major operating systems
  // eslint-disable-next-line
  const invalidChars = /[<>:"\/\\|?*\x00-\x1F]/g

  // Replace invalid characters with an underscore
  let validFileName = input.replace(invalidChars, '_')

  // Trim leading and trailing spaces and dots
  validFileName = validFileName.trim().replace(/^\.+|\.+$/g, '')

  // Ensure the file name is not empty after processing
  if (validFileName.length === 0) {
    validFileName = 'download'
  }

  // Limit the file name length to a typical file system maximum (e.g., 255 characters)
  const maxLength = 255
  if (validFileName.length > maxLength) {
    validFileName = validFileName.substring(0, maxLength)
  }

  return validFileName
}

export function replaceDiacritics(input: string): string {
  return input.normalize('NFKD').replace(/[^\w]/g, '')
}
