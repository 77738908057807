<template>
  <div>
    <div class="relative md:pb-40 break-inside-avoid">
      <div class="w-full text-xs grid gap-10 relative z-10">
        <div
          v-for="item in items"
          class="flex flex-col md:flex-row gap-5 md:gap-10"
        >
          <h4
            class="md:bg-gray-100 uppercase font-medium md:flex md:items-center md:px-10 mobile-only:w-auto"
            :style="{
              width: width + 'px',
            }"
          >
            {{ item.title }}
          </h4>
          <div class="flex flex-1">
            <div
              v-for="(cell, i) in item.cells"
              class="text-white font-medium h-30 md:h-50 relative chart-bar-distribution-cell"
              :style="{
                flex: Math.round(cell.value * 10000).toFixed(0),
                backgroundColor: colors[i],
              }"
            >
              <div
                class="absolute bottom-full left-1/2 -translate-x-1/2 bg-gray-100 text-gray-900 mb-5 chart-bar-distribution-cell-tooltip"
                :class="{
                  'always-show-on-hover': valuesOnHover,
                }"
              >
                <div class="px-10 py-5 text-sm">
                  {{ cell.formatted }}
                </div>
              </div>
              <div
                v-if="!valuesOnHover"
                class="absolute left-0 top-0 w-full h-full flex items-center justify-center chart-bar-distribution-cell-value"
              >
                <div class="px-10 overflow-hidden">
                  {{ cell.formatted }}
                </div>
              </div>
            </div>

            <div
              v-if="item.total < largestOnAxis"
              :style="{
                flex: Math.round(
                  (largestOnAxis - item.total) * 10000,
                ).toFixed(),
              }"
            />
          </div>
        </div>
      </div>
      <div class="absolute top-0 left-0 w-full h-full hidden md:block">
        <div class="flex justify-between h-full">
          <div
            v-for="(x, index) in xAxis"
            class="relative flex-1 h-full text-right border-r border-dashed border-r-gray-500 flex items-end text-xs mobile-only:!flex-1"
            :style="
              index === 0
                ? {
                    flex: '0 0 ' + (width + 10 + 'px'),
                  }
                : {}
            "
          >
            <div class="pr-5 leading-[0.75] flex-1 text-right pt-15 border-t">
              {{ x.label }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <ChartLegend :items="dataPoints" :colors="colors" />
  </div>
</template>

<script setup lang="ts">
import {
  getColorPalette,
  getSaneNumber,
  type ChartBarDistribution,
  type ChartColorPalette,
} from '~/helpers/charts'

const props = defineProps<{
  table: string[][]
  distribution: ChartBarDistribution
  colorPalette: ChartColorPalette
  valuePrefix?: string
  valueSuffix?: string
  valuesOnHover?: boolean
  decimalPoints: number
}>()

function getFormattedValue(value: number): string {
  const roundedNumber = value.toFixed(props.decimalPoints)
  return `${props.valuePrefix || ''}${roundedNumber}${props.valueSuffix || ''}`
}

const width = computed(() => {
  const longestTitleWord = items.value.reduce((acc, v) => {
    const words = v.title.split(' ')
    for (let i = 0; i < words.length; i++) {
      const word = words[i]

      if (word.length > acc) {
        return word.length
      }
    }
    return acc
  }, 0)
  return Math.min(longestTitleWord * 10 + 20, 280)
})

function filterEmpty(v: any) {
  return v !== undefined && v !== null && typeof v === 'string' && v.length
}

const dataPoints = computed(() => {
  return props.table.map((v) => v[0]).filter(filterEmpty)
})

const labels = computed(() => props.table[0].map((v) => v).filter(filterEmpty))

const items = computed(() => {
  return labels.value.map((_v, index) => {
    const data = props.table.slice(1).map((values) => {
      const value = values[index + 1]
      return value
    })
    const values = data.map((v) => {
      if (typeof v === 'string') {
        const num = parseFloat(v)
        if (isNaN(num)) {
          return 0
        }

        return num
      }

      return 0
    })
    const total = values.reduce((acc, v) => {
      return acc + v
    }, 0)

    return {
      title: labels.value[index],
      total,
      values,
      cells: values.map((value) => {
        return {
          value,
          formatted: getFormattedValue(value),
        }
      }),
    }
  })
})

const largest = computed(() =>
  items.value.reduce((acc, v) => {
    if (v.total > acc) {
      return v.total
    }
    return acc
  }, 0),
)

const largestOnAxis = computed(() => {
  if (props.distribution === 'percentage') {
    return 100
  }
  const lastAxis = xAxis.value[xAxis.value.length - 1]?.value
  if (!lastAxis) {
    return 100
  }

  return lastAxis
})

type XAxis = {
  label: string
  value?: number
}

const xAxis = computed<XAxis[]>(() => {
  if (props.distribution === 'percentage') {
    return [
      { label: '0%' },
      { label: '25%' },
      { label: '50%' },
      { label: '75%' },
      { label: '100%' },
    ]
  }

  const range = getSaneNumber(largest.value, false)
  const step = getSaneNumber(range / 8, true)
  const niceMin = 0
  const niceMax = Math.ceil(largest.value / step) * step
  const tickCount = Math.round((niceMax - niceMin) / step) + 1

  const fixed = (step.toString().split('.')[1] || '').length

  return Array.from({ length: tickCount }, (_, i) => {
    const value = i * step
    const valueFixed = value.toFixed(fixed)
    const label = `${props.valuePrefix || ''}${valueFixed}${props.valueSuffix || ''}`
    return { label, value }
  })
})

const colors = computed(() => getColorPalette(props.colorPalette))
</script>

<style lang="postcss">
.chart-bar-distribution-cell {
  container-type: inline-size;
}
.chart-bar-distribution-cell-tooltip {
  @apply pointer-events-none invisible;
  .chart-bar-distribution-cell:hover & {
    @apply visible;
  }

  &:not(.always-show-on-hover) {
    @container (min-width: 50px) {
      @apply !invisible;
    }
  }
}
.chart-bar-distribution-cell-value {
  @apply hidden;
  /* Value is only visible if the container is wide enough. */
  @container (min-width: 50px) {
    @apply flex;
  }
}
</style>
