import { useWindowSize } from '@vueuse/core'

type Viewport = 'sm' | 'md' | 'lg' | 'xl'

export function useViewport() {
  const size = useWindowSize()
  const route = useRoute()
  const isMobileMenu = computed(() => {
    return size.width.value < 1024
  })

  const viewport = computed<Viewport>(() => {
    if (size.width.value >= 1210) {
      return 'xl'
    } else if (size.width.value >= 1024) {
      return 'lg'
    } else if (size.width.value >= 768) {
      return 'md'
    }

    return 'sm'
  })

  const isPrint = computed(() => route.meta.layout === 'print')

  return { isMobileMenu, viewport, isPrint }
}
