<template>
  <div
    class="bg-gradient-to-r p-15 md:p-20 lg:p-30 max-w-prose my-15 md:my-30 relative overflow-hidden"
    :class="{
      'from-blue to-cyan': options.factStyle === 'blue',
      'from-orange to-orange-light': options.factStyle === 'orange',
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <MediaIcon
      v-if="icon"
      v-bind="icon"
      class="text-white h-50 md:h-[240px] absolute right-15 md:-right-30 top-15 md:top-1/2 md:-translate-y-1/2 md:opacity-30"
    />

    <h3
      v-if="title"
      v-blokkli-editable:field_title
      class="font-bold text-2xl md:text-4xl lg:text-6xl leading-none text-white"
    >
      {{ title }}
    </h3>
    <p
      v-if="subtitle"
      v-blokkli-editable:field_subtitle
      class="text-lg md:text-lg lg:text-2xl leading-none text-white"
    >
      {{ subtitle }}
    </p>
    <div
      v-if="text"
      v-blokkli-editable:paragraphs_text
      class="text-white mt-15 md:mt-30 max-w-hero ck-content text-balance paragraph-fact-text"
      v-html="text"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphFactFragment } from '#build/graphql-operations'

const { options } = defineBlokkli({
  bundle: 'fact',
  globalOptions: ['isHidden'],
  options: {
    factStyle: {
      type: 'radios',
      label: 'Farbe',
      default: 'blue',
      displayAs: 'colors',
      options: {
        blue: { class: 'bg-gradient-to-r from-blue to-cyan', label: 'Blau' },
        orange: {
          class: 'bg-gradient-to-r from-orange to-orange-light',
          label: 'Orange',
        },
      },
    },
  },
})

defineProps<{
  title?: string
  subtitle?: string
  text?: string
  icon?: ParagraphFactFragment['icon']
}>()
</script>

<style lang="postcss">
.paragraph-fact-text {
  ul {
    @apply md:columns-2;
  }
}
</style>
