<template>
  <div class="mb-80 mt-40">
    <div
      v-if="boxes"
      class="grid grid-cols-1 lg:grid-cols-[1fr_1fr_1fr] gap-20"
    >
      <HomeBox v-for="(box, i) in boxes" v-bind="box" :key="i" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { HomeBoxFragment } from '#build/graphql-operations'

defineBlokkliFragment({
  name: 'home_teasers',
  label: 'Startseite Teaser',
  description: 'Zeigt die drei konfigurierten Teaser für die Startseite an.',
  editor: {
    previewWidth: 1200,
  },
})

const providedBoxes = inject<ComputedRef<HomeBoxFragment[]> | null>(
  'home_teasers',
  null,
)

const boxes = computed(() => providedBoxes?.value || [])
</script>
