<template>
  <slot :top="customTitle.top" :bottom="customTitle.bottom"></slot>
</template>

<script lang="ts" setup>
import { splitTitle } from '~/helpers/splitTitle'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  boldCount: {
    type: Number,
    default: 1,
  },
})

const customTitle = computed(() => {
  return splitTitle(props.title, props.boldCount)
})
</script>
