<template>
  <div
    class="bg-white my-30"
    :class="{
      'max-w-prose': options.width === 'narrow',
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <div class="bg-white">
      <ParagraphChartBar
        v-if="options.chartType === 'bar' && tableData.rows?.length"
        :table="tableData.rows"
        :distribution="options.barDistribution"
        :color-palette="options.colorPalette"
        :muted-count="options.mutedCount"
        :value-suffix="valueSuffix"
        :value-prefix="valuePrefix"
        :values-on-hover="options.valuesOnHover && !isPrint"
        :decimal-points="options.decimalPoints"
        :axis-label="axisLabels?.[0]"
      />
      <ParagraphChartPie
        v-else-if="options.chartType === 'pie' && tableData.rows?.length"
        :table="tableData.rows"
        :color-palette="options.colorPalette"
        :rotation="options.rotationOffset"
        :offset-x="options.pieOffsetX"
        :is-wide="options.width === 'full'"
        :muted-count="options.mutedCount"
        :axis-labels="axisLabels"
        :value-suffix="valueSuffix"
        :value-prefix="valuePrefix"
        :footnotes="footnotes"
        :decimal-points="options.decimalPoints"
      />
      <ParagraphChartLine
        v-else-if="options.chartType === 'line' && tableData.rows?.length"
        :table="tableData.rows"
        :color-palette="options.colorPalette"
        :muted-count="options.mutedCount"
        :is-wide="options.width === 'full'"
        :padding-top-multiplicator="options.linePaddingTop"
        :padding-bottom-multiplicator="options.linePaddingBottom"
        :padding-left-override="options.linePaddingLeft"
        :axis-labels="axisLabels"
        :value-suffix="valueSuffix"
        :value-prefix="valuePrefix"
        :decimal-points="options.decimalPoints"
      />
      <ParagraphChartMatrix
        v-else-if="options.chartType === 'matrix' && tableData?.rows"
        :table="tableData.rows"
        :color-palette="options.colorPalette"
        :is-wide="options.width === 'full'"
        :footnotes="footnotes"
      />
      <ParagraphChartMap
        v-else-if="options.chartType === 'map' && tableData?.rows"
        :table="tableData.rows"
        :color-palette="options.colorPalette"
        :is-wide="options.width === 'full'"
        :footnotes="footnotes"
      />

      <div v-if="copyright" class="mt-15 text-xs uppercase text-gray-700">
        {{ copyright }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphChartFragment } from '#graphql-operations'

withDefaults(
  defineProps<{
    copyright?: string
    footnotes?: string[]
    tableData?: ParagraphChartFragment['tableData']
    axisLabels?: string[]
    valuePrefix?: string
    valueSuffix?: string
  }>(),
  {
    copyright: '',
    footnotes: () => [],
    axisLabels: () => [],
    tableData: () => {
      return {
        rows: [],
      }
    },
  },
)

const { options } = defineBlokkli({
  bundle: 'chart',
  globalOptions: ['isHidden'],
  options: {
    chartType: {
      type: 'radios',
      label: 'Diagrammtyp',
      default: 'bar',
      displayAs: 'icons',
      options: {
        bar: { label: 'Balken', icon: 'icon-blokkli-option-chart-bar' },
        pie: { label: 'Kuchen', icon: 'icon-blokkli-option-chart-pie' },
        line: { label: 'Linie', icon: 'icon-blokkli-option-chart-line' },
        matrix: { label: 'Matrix', icon: 'icon-blokkli-option-chart-matrix' },
        map: { label: 'Karte', icon: 'icon-blokkli-option-chart-map' },
      },
    },

    mutedCount: {
      type: 'number',
      label: 'Ausgrauen',
      default: 0,
      min: 0,
      max: 20,
    },

    decimalPoints: {
      type: 'number',
      label: 'Kommastellen',
      default: 1,
      min: 0,
      max: 5,
    },

    width: {
      type: 'radios',
      label: 'Breite',
      default: 'narrow',
      options: {
        narrow: 'Schmal',
        full: 'Breit',
      },
    },

    pieOffsetX: {
      type: 'range',
      label: 'Offset X',
      default: 0,
      min: -2,
      max: 2,
      step: 0.05,
    },

    linePaddingBottom: {
      type: 'range',
      label: 'Padding (unten)',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    linePaddingTop: {
      type: 'range',
      label: 'Padding (oben)',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    linePaddingLeft: {
      type: 'range',
      label: 'Padding (links)',
      default: 0,
      min: 0,
      max: 200,
      step: 1,
    },

    rotationOffset: {
      type: 'range',
      label: 'Rotation',
      default: 0,
      min: 0,
      max: 1,
      step: 0.01,
    },

    colorPalette: {
      type: 'radios',
      label: 'Farbpalette',
      default: 'berry',
      displayAs: 'colors',
      options: {
        mixed: { label: 'Mixed', class: 'bg-chart-mixed' },
        berry: { label: 'Berry', class: 'bg-berry' },
        orange: { label: 'Orange', class: 'bg-orange' },
        blue: { label: 'Blau', class: 'bg-blue' },
      },
    },

    barDistribution: {
      type: 'radios',
      label: 'Verteilung',
      default: 'percentage',
      options: {
        percentage: 'Prozentual',
        largest: 'Grösster Wert',
      },
    },

    valuesOnHover: {
      type: 'checkbox',
      label: 'Werte on Hover',
      default: false,
    },
  },

  editor: {
    determineVisibleOptions: (ctx) => {
      if (ctx.options.chartType === 'bar') {
        return [
          'isHidden',
          'chartType',
          'width',
          'mutedCount',
          'barDistribution',
          'colorPalette',
          'valuesOnHover',
          'decimalPoints',
        ]
      } else if (ctx.options.chartType === 'pie') {
        return [
          'isHidden',
          'chartType',
          'width',
          'pieOffsetX',
          'rotationOffset',
          'colorPalette',
          'mutedCount',
          'decimalPoints',
        ]
      } else if (ctx.options.chartType === 'line') {
        return [
          'isHidden',
          'chartType',
          'width',
          'colorPalette',
          'mutedCount',
          'linePaddingTop',
          'linePaddingBottom',
          'linePaddingLeft',
          'decimalPoints',
        ]
      } else if (ctx.options.chartType === 'matrix') {
        return ['isHidden', 'chartType', 'colorPalette', 'mutedCount', 'width']
      } else if (ctx.options.chartType === 'map') {
        return ['isHidden', 'chartType', 'colorPalette', 'mutedCount', 'width']
      }

      return ['isHidden', 'chartType']
    },
  },
})

const { isPrint } = useViewport()
</script>

<style lang="postcss">
.bg-chart-mixed {
  background: conic-gradient(
    theme('colors.berry'),
    theme('colors.orange'),
    theme('colors.green'),
    theme('colors.blue'),
    theme('colors.cyan'),
    theme('colors.lime')
  );
}
</style>
