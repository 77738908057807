<template>
  <DrupalLink :to="to">
    <div
      v-if="hasIcon && (computedIconBefore || iconName || computedIcon)"
      class="link-icon relative mr-10 flex-shrink-0"
      :data-document-type="computedIconBefore"
    >
      <SpriteSymbol
        v-if="hasIcon && (iconName || computedIcon)"
        :name="iconName || computedIcon"
        class="h-20 w-20"
      />
    </div>
    {{ title }}
  </DrupalLink>
</template>

<script lang="ts" setup>
import { LinkFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  uri?: LinkFragment['uri']
  hasIcon?: boolean
  iconName?: string
}>()

const to = computed(() => {
  if (props.uri?.__typename === 'EntityCanonicalUrl') {
    const entity = props.uri.entity
    if (entity && 'mediaFileUrl' in entity && entity.mediaFileUrl?.path) {
      return entity.mediaFileUrl?.path
    }
  }
  return props.uri?.path
})

const computedIcon = computed(() => {
  if (props?.uri?.__typename === 'ExternalUrl') {
    return 'external'
  } else if (/(pdf)$/gi.test(to.value || '')) {
    return 'pdf'
  } else if (/(docx|doc|pptx|xls|xlsx|zip)$/gi.test(to.value || '')) {
    return 'document'
  } else if (/(mp4)$/gi.test(to.value || '')) {
    return 'play'
  }
  return 'arrow'
})

const computedIconBefore = computed(() => {
  if (/(docx?)$/gi.test(to.value || '')) {
    return 'W'
  } else if (/(xlsx?)$/gi.test(to.value || '')) {
    return 'X'
  } else if (/(pptx?)$/gi.test(to.value || '')) {
    return 'P'
  } else if (/(zip)$/gi.test(to.value || '')) {
    return 'ZIP'
  }
})

const title = computed(() => {
  if (props.title) {
    return props.title
  }

  const url = props.uri
  if (url && 'entity' in url) {
    return url.entity?.label
  }

  return to.value
})
</script>

<style lang="postcss">
.link-icon[data-document-type] {
  &:before {
    content: attr(data-document-type);
    @apply block absolute top-3 left-0 w-full text-center font-semibold text-xs pointer-events-none;
  }
}
.link-icon[data-document-type='ZIP']:before {
  @apply text-[7px];
}
</style>
