<template>
  <div class="mt-90">
    <EventsSlider />
  </div>
</template>

<script lang="ts" setup>
defineBlokkliFragment({
  name: 'home_events_slider',
  label: 'Startseite Events',
  description: 'Zeigt die aktuellen Events für die Startseite an.',
  editor: {
    previewWidth: 1200,
  },
})
</script>
