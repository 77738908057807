<template>
  <div
    class="box relative"
    :class="[
      ...classes,
      {
        'bk-paragraph-region-affected': options.isHidden,
      },
    ]"
  >
    <div class="box-padding" :class="{ 'mb-10': !!ctaLink }">
      <TitleWithIcon
        :title="title"
        :icon="icon"
        :hint-color="iconColor"
        :title-class="isBerry ? 'text-white' : 'text-gray-900'"
        drupal-icon-field-name="field_media_icon"
      >
        <template #title>
          <span v-blokkli-editable:field_title>{{ title }}</span>
        </template>
      </TitleWithIcon>

      <div class="mt-20 lg:mt-40 flex flex-col gap-20">
        <BlokkliField :list="paragraphs" name="field_paragraphs" />
      </div>
    </div>
    <div class="box-padding py-0 box-pull-down absolute bottom-0 left-0">
      <ButtonStyle v-slot="{ classes: buttonClasses }" :color="ctaColor">
        <FieldItemTypeLink
          v-if="ctaLink"
          v-bind="ctaLink"
          :class="buttonClasses"
        />
      </ButtonStyle>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ParagraphLinkBoxFragment, SiteColor } from '#graphql-operations'

const { index, options } = defineBlokkli({
  bundle: 'link_box',
  globalOptions: ['isHidden'],
})

const props = defineProps<{
  title: string
  icon: ParagraphLinkBoxFragment['icon']
  ctaLink: ParagraphLinkBoxFragment['ctaLink']
  paragraphs: ParagraphLinkBoxFragment['paragraphs']
}>()

const columns = computed(() => {
  return index.value > 0 ? 2 : 1
})

const isBerry = computed(() => {
  return columns.value === 2
})
const iconColor = computed(() => {
  return isBerry.value ? 'text-white' : 'text-green'
})

const ctaColor = computed(() => {
  return isBerry.value ? SiteColor.PINK : SiteColor.GREEN_ALT
})

const classes = computed(() => {
  const classes: string[] = ['col-span-full', 'md:col-span-1']
  if (isBerry.value) {
    classes.push('bg-berry')
  } else {
    classes.push('bg-white')
  }

  if (columns.value === 2) {
    classes.push('lg:col-span-2')
  }

  if (props.ctaLink) {
    classes.push('mobile-only:mb-30')
  }

  return classes
})

const background = computed(() => {
  return isBerry.value ? 'BERRY' : ''
})

// Provide background for child paragraphs to render accordingly.
provide('background', background)

// Provide columns information to child paragraphs.
provide('linkColumns', columns)
</script>
