<template>
  <div
    class="box box-padding mb-25 hyphens-auto relative flex flex-col"
    :class="classes"
  >
    <div class="w-full max-w-[300px]">
      <h2 v-if="title" class="font-semibold text-xl md:text-4xl mb-10">
        {{ title }}
      </h2>
      <h3 v-if="subtitle" class="text-lg md:text-xl mb-20 md:mb-30 lg:mb-90">
        {{ subtitle }}
      </h3>
    </div>
    <div
      v-if="icon"
      class="pointer-events-none overflow-hidden absolute top-0 right-0 h-full aspect-square"
    >
      <MediaIcon
        v-bind="icon"
        class="opacity-40 w-full h-full transform translate-x-1/3 scale-75"
      />
    </div>

    <div
      v-if="links"
      class="flex flex-col gap-10 mt-auto relative z-20 -mb-30 md:-mb-[40px] lg:-mb-40 xl:-mb-50"
    >
      <ButtonStyle v-slot="{ classes: buttonClasses }" :color="buttonColor">
        <div v-for="link in links">
          <FieldItemTypeLink v-bind="link" :class="buttonClasses" />
        </div>
      </ButtonStyle>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  LinkFragment,
  MediaIconFragment,
  SiteColorFragment,
} from '#graphql-operations'
import { getBoxClasses, getComplementaryColor } from '~/helpers/siteColor'

const props = defineProps<{
  title?: string
  subtitle?: string
  icon?: MediaIconFragment
  links?: LinkFragment[]
  color?: SiteColorFragment
}>()

const classes = computed(() => getBoxClasses(props.color)[0][0])
const buttonColor = computed(() => getComplementaryColor(props.color))
</script>
