<template>
  <PageFooterColumn :title="title" :subtitle="subtitle">
    <ul>
      <li v-for="link in links">
        <nuxt-link :to="link?.uri?.path" class="footer-link">
          <SpriteSymbol v-if="index > 2" name="arrow" class="w-20 h-10 mr-5" />
          <span>{{ getLinkLabel(link) }}</span>
        </nuxt-link>
      </li>
    </ul>
  </PageFooterColumn>
</template>

<script lang="ts" setup>
import {
  FooterLinkFragment,
  ParagraphFooterSectionLinksFragment,
} from '#graphql-operations'

const { index } = defineBlokkli({
  bundle: 'footer_section_links',
})

defineProps<{
  title?: string
  subtitle?: string
  links: ParagraphFooterSectionLinksFragment['links']
}>()

function getLinkLabel(link: FooterLinkFragment): string {
  if (link.title) {
    return link.title
  }
  if (link.uri && 'entity' in link.uri) {
    return link?.uri?.entity?.label || ''
  }
  return ''
}
</script>
