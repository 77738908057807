<template>
  <div class="mt-40">
    <TitleWithIcon
      :title="$texts('infoletterNews', 'Infoletter: die neusten Artikel')"
      title-class="text-lg"
    />
    <InfoletterList class="mt-40" />

    <div class="text-center mt-60">
      <a
        href="https://info.pharmasuisse.org"
        target="_blank"
        class="button is-primary"
        >{{ $texts('showAllNews', 'Alle News anzeigen') }}</a
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
defineBlokkliFragment({
  name: 'home_infoletter_list',
  label: 'Startseite Infoletter',
  description: 'Zeigt die aktuellen Infoletter Artikel für die Startseite an.',
  editor: {
    previewWidth: 1200,
  },
})
</script>
