<template>
  <div
    class="paragraph-product-teaser my-10 md:my-30"
    :class="{
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <CommerceProduct v-if="product" v-bind="product" :display-button="true" />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphProductTeaserFragment } from '#graphql-operations'

defineOptions({
  name: 'ParagraphProductTeaser',
})

const { options } = defineBlokkli({
  bundle: 'product_teaser',
  globalOptions: ['isHidden'],
})

defineProps<{
  product: ParagraphProductTeaserFragment['product']
}>()
</script>
