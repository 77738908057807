export const CANTON_LABELS: Record<string, Record<string, string>> = {
  ZH: {
    de: 'Zürich',
    fr: 'Zurich',
    it: 'Zurigo',
  },
  BE: {
    de: 'Bern',
    fr: 'Berne',
    it: 'Berna',
  },
  LU: {
    de: 'Luzern',
    fr: 'Lucerne',
    it: 'Lucerna',
  },
  UR: {
    de: 'Uri',
    fr: 'Uri',
    it: 'Uri',
  },
  SZ: {
    de: 'Schwyz',
    fr: 'Schwyz',
    it: 'Svitto',
  },
  OW: {
    de: 'Obwalden',
    fr: 'Obwald',
    it: 'Obvaldo',
  },
  NW: {
    de: 'Nidwalden',
    fr: 'Nidwald',
    it: 'Nidvaldo',
  },
  GL: {
    de: 'Glarus',
    fr: 'Glaris',
    it: 'Glarona',
  },
  ZG: {
    de: 'Zug',
    fr: 'Zoug',
    it: 'Zugo',
  },
  FR: {
    de: 'Freiburg',
    fr: 'Fribourg',
    it: 'Friburgo',
  },
  SO: {
    de: 'Solothurn',
    fr: 'Soleure',
    it: 'Soletta',
  },
  BS: {
    de: 'Basel-Stadt',
    fr: 'Bâle-Ville',
    it: 'Basilea Città',
  },
  BL: {
    de: 'Basel-Landschaft',
    fr: 'Bâle-Campagne',
    it: 'Basilea Campagna',
  },
  SH: {
    de: 'Schaffhausen',
    fr: 'Schaffhouse',
    it: 'Sciaffusa',
  },
  AR: {
    de: 'Appenzell Ausserrhoden',
    fr: 'Appenzell Rhodes-Extérieures',
    it: 'Appenzello Esterno',
  },
  AI: {
    de: 'Appenzell Innerrhoden',
    fr: 'Appenzell Rhodes-Intérieures',
    it: 'Appenzello Interno',
  },
  SG: {
    de: 'St. Gallen',
    fr: 'Saint-Gall',
    it: 'San Gallo',
  },
  GR: {
    de: 'Graubünden',
    fr: 'Grisons',
    it: 'Grigioni',
  },
  AG: {
    de: 'Aargau',
    fr: 'Argovie',
    it: 'Argovia',
  },
  TG: {
    de: 'Thurgau',
    fr: 'Thurgovie',
    it: 'Turgovia',
  },
  TI: {
    de: 'Tessin',
    fr: 'Tessin',
    it: 'Ticino',
  },
  VD: {
    de: 'Waadt',
    fr: 'Vaud',
    it: 'Vaud',
  },
  VS: {
    de: 'Wallis',
    fr: 'Valais',
    it: 'Vallese',
  },
  NE: {
    de: 'Neuenburg',
    fr: 'Neuchâtel',
    it: 'Neuchâtel',
  },
  GE: {
    de: 'Genf',
    fr: 'Genève',
    it: 'Ginevra',
  },
  JU: {
    de: 'Jura',
    fr: 'Jura',
    it: 'Giura',
  },
}
