<template>
  <div class="box bg-white">
    <div class="flex">
      <div
        v-if="imageVisible"
        class="shrink-0 hidden md:block xl:w-[369px] aspect-square"
      >
        <MediaImage v-bind="image" :image-style="imageStyle" />
      </div>
      <div class="flex-1" :class="{ 'md:min-h-[369px]': !imageVisible }">
        <div class="box-padding h-full flex flex-col pb-0">
          <SpriteSymbol
            name="email"
            class="text-green w-50 h-50 md:w-80 md:h-80"
          />
          <Divider class="mt-10 mb-20 text-green" />
          <h2 class="text-xl md:text-2xl lg:text-3xl mb-10">{{ name }}</h2>
          <p v-if="position" class="text-md uppercase font-medium">
            {{ position }}
          </p>

          <a
            v-if="phoneLink"
            :href="phoneLink"
            class="link flex items-center mt-20"
          >
            <SpriteSymbol name="phone" class="h-25 w-25 mr-5" />
            <span>{{ phoneFormatted }}</span>
          </a>
          <div class="mt-20 md:mt-auto">
            <div v-if="mailLink" class="box-pull-down">
              <ButtonStyle v-slot="{ classes }">
                <a :href="mailLink" :class="classes">
                  <SpriteSymbol name="arrow" class="h-10 w-20 mr-10" />
                  {{ $texts('email', 'E-Mail') }}
                </a>
              </ButtonStyle>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ContactBoxPersonFragment } from '#graphql-operations'

const props = defineProps<{
  name?: ContactBoxPersonFragment['name']
  phone?: ContactBoxPersonFragment['phone']
  email?: ContactBoxPersonFragment['email']
  position?: ContactBoxPersonFragment['position']
  image?: ContactBoxPersonFragment['image']
  hideImage?: boolean
}>()

const phoneLink = computed(() => props.phone?.first?.parsed?.link || '')
const phoneFormatted = computed(() => props.phone?.first?.parsed?.national)
const mailLink = computed(() => (props.email ? `mailto:${props.email}` : ''))
const imageVisible = computed(() => props.image && !props.hideImage)

const imageStyle = defineImageStyle({
  type: 'sizes',
  aspectRatio: 1,
  sizes: {
    md: 364,
    lg: 323,
    xl: 369,
  },
})
</script>
