<template>
  <div
    ref="rootEl"
    class="fixed top-0 left-0 flex w-screen h-screen bg-gray-50 z-modal"
  >
    <UseFocusTrap :options="{ immediate: true }" class="w-full">
      <div
        class="modal-content relative flex items-center justify-center w-full h-full"
      >
        <button
          class="absolute top-0 right-0 p-15"
          :title="$texts('modalClose', 'Schliessen')"
          @click.prevent="onClose"
        >
          <SpriteSymbol
            name="close"
            class="w-20 h-20 md:w-30 md:h-30 fill-current text-green hover:text-green-alt"
          />
        </button>
        <slot></slot>
      </div>
    </UseFocusTrap>
  </div>
</template>

<script setup lang="ts">
import { onKeyStroke } from '@vueuse/core'
import { UseFocusTrap } from '@vueuse/integrations/useFocusTrap/component'
import {
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock-upgrade'

const emit = defineEmits<{
  (e: 'close'): void
}>()

const rootEl = ref<HTMLDivElement | null>(null)

function onClose() {
  emit('close')
}

onKeyStroke('Escape', onClose)

onMounted(() => {
  if (rootEl.value) {
    disableBodyScroll(rootEl.value, {
      reserveScrollBarGap: true,
    })
  }
})

onBeforeUnmount(() => {
  clearAllBodyScrollLocks()
})
</script>
