<template>
  <svg
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    class="w-[10px] aspect-square md:w-[16px]"
  >
    <circle
      v-if="symbol === 1 || symbol === 4"
      cx="8"
      cy="8"
      r="5"
      stroke-width="2"
      :class="[symbol < 4 ? fillClass : 'fill-transparent', strokeClass]"
    />
    <polygon
      v-else-if="symbol === 2 || symbol === 5"
      points="8,3.5 13,12 3,12 8,3.5"
      stroke-width="2"
      :class="[symbol < 4 ? fillClass : 'fill-transparent', strokeClass]"
    />
    <rect
      v-else
      x="3.5"
      y="3.5"
      width="9"
      height="9"
      stroke-width="2"
      :class="[symbol < 4 ? fillClass : 'fill-transparent', strokeClass]"
    />
  </svg>
</template>

<script setup lang="ts">
const props = defineProps<{
  symbol: number
  inverted?: boolean
}>()

const fillClass = computed(() =>
  props.inverted ? 'fill-white' : 'fill-gray-500',
)
const strokeClass = computed(() =>
  props.inverted ? 'stroke-white' : 'stroke-gray-500',
)
</script>
