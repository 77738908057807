export const COLORS = {
  // Monochrome colors.
  white: '#ffff',
  current: 'currentColor',
  transparent: 'transparent',
  'gray-50': '#F2F4F5;',
  'gray-100': '#EAEDEF',
  'gray-300': '#D4D5D5',
  'gray-500': '#A4A5A5',
  'gray-700': '#838383',
  'gray-900': '#303030',

  // PHS orange 1.1
  orange: '#d58925',
  // PHS orange 1.2
  'orange-light': '#e7c081',
  // PHS Gelb 1.1
  yellow: '#efd51f',
  // PHS Gelb 1.2
  'yellow-light': '#f9ef79',
  // PHS Grün 1.1 && FPHCH Grün
  green: '#009932',
  // PHS Grün 1.2
  'green-light': '#aacea3',
  // PHS Grün 1.3
  'green-lighter': '#cce1c6',
  // PHS Grün 2.1
  'green-alt': '#68ab44',
  // PHS Blau 1.1
  cyan: '#178ed3',
  // PHS Blau 1.2
  'cyan-light': '#89bdeb',
  // PHS Blau 1.3
  'cyan-lighter': '#c8e1f8',
  // PHS Blau 2.1
  blue: '#224e8d',
  // PHS Blau 2.2
  'blue-light': '#6891cb',
  // PHS Blau 2.3
  'blue-lighter': '#9bbbe5',
  // PHS pink 1.1
  berry: '#8b106f',
  // PHS pink 1.2
  'berry-light': '#ac5c8d',
  // PHS pink 1.3
  'berry-lighter': '#be96b0',
  // PHS pink 2.1
  pink: '#c60086',
  // PHS pink 2.2
  'pink-light': '#d376ae',
  // PHS pink 2.3
  'pink-lighter': '#ffb1d0',
  // PHS Fehlermeldungen
  red: '#d52525',
  // custom
  'red-light': '#edd6d6',
  // PHS Grün 3.1
  lime: '#a5c400',
  // PHS Grün 3.1
  'lime-light': '#d2e180',
  // FPHCH Blau
  'fphch-blue': '#4269a5',
  // FPHCH Gelb
  'fphch-yellow': '#ebca3c',
} as const
