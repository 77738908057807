<template>
  <PageFooterColumn :title="title">
    <ul>
      <li v-for="link in links">
        <nuxt-link
          :to="link?.uri?.path"
          class="footer-link flex items-center mb-15"
        >
          <SpriteSymbol
            v-if="link.uri"
            :name="getIconName(link.uri.path)"
            class="w-20 h-20 lg:w-30 lg:h-30 fill-current mr-3 lg:mr-5"
          />
          {{ link?.title }}
        </nuxt-link>
      </li>
    </ul>
  </PageFooterColumn>
</template>

<script lang="ts" setup>
import { ParagraphFooterSectionSocialMediaLinkFragment } from '#graphql-operations'

defineBlokkli({
  bundle: 'footer_section_social_media_link',
})

defineProps<{
  title?: string
  links: ParagraphFooterSectionSocialMediaLinkFragment['links']
}>()

function getIconName(url = '') {
  if (url.includes('twitter.com')) {
    return 'twitter'
  } else if (url.includes('facebook.com')) {
    return 'facebook'
  } else if (url.includes('linkedin.com')) {
    return 'linkedin'
  } else if (url.includes('tiktok.com')) {
    return 'tiktok'
  } else if (url.includes('instagram.com')) {
    return 'instagram'
  } else if (url.includes('youtube.com')) {
    return 'youtube'
  }
}
</script>
