import { COLORS } from '../colors'

export type ChartColorPalette = 'berry' | 'orange' | 'blue' | 'mixed'

export type ChartBarDistribution = 'percentage' | 'largest'

const BERRY = [
  '#5F0149',
  COLORS.berry,
  COLORS.pink,
  COLORS['pink-light'],
  COLORS['pink-lighter'],
]

const ORANGE = ['#975600', COLORS.orange, '#DBAF12', COLORS.yellow, '#F2E378']

const BLUE = [
  '#172A6E',
  COLORS.blue,
  COLORS.cyan,
  COLORS['cyan-light'],
  COLORS['cyan-lighter'],
]

export const GRAY = [
  '#EAEDEF',
  '#D4D5D5',
  '#A4A5A5',
  '#666666',
  '#444444',
  '#303030',
]

const MIXED = [
  BLUE[1],
  BERRY[1],
  ORANGE[1],
  BLUE[2],
  BERRY[2],
  ORANGE[2],
  BLUE[3],
  BERRY[3],
  ORANGE[3],
  BLUE[4],
  BERRY[4],
  ORANGE[4],
  BLUE[0],
  BERRY[0],
  ORANGE[0],
]

export function getColorPalette(color: ChartColorPalette): string[] {
  if (color === 'berry') {
    return [...BERRY, ...[...ORANGE].reverse()]
  } else if (color === 'blue') {
    return BLUE
  } else if (color === 'orange') {
    return [...ORANGE, ...[...BERRY].reverse()]
  }

  return MIXED
}

export function getSymbols(value: string): number[] {
  const match = /{([^}]*)}/g.exec(value)
  return (
    match?.[1]
      .split(',')
      .map((v) => {
        return parseInt(v.trim())
      })
      .filter((v) => !isNaN(v)) || []
  )
}

export function getNiceFraction(fraction: number, round: boolean) {
  if (round) {
    if (fraction < 1.5) {
      return 1
    } else if (fraction < 3) {
      return 2
    } else if (fraction < 7) {
      return 5
    }
  } else if (fraction <= 1) {
    return 1
  } else if (fraction <= 2) {
    return 2
  } else if (fraction <= 5) {
    return 5
  }

  return 10
}

export function getSaneNumber(range: number, round: boolean) {
  const exponent = Math.floor(Math.log10(range))
  const fraction = range / Math.pow(10, exponent)
  const niceFraction = getNiceFraction(fraction, round)
  return niceFraction * Math.pow(10, exponent)
}

export function convertFootnotes(v: string): string {
  return v.replace(/\{(\d+(?:,\s*\d+)*)\}/, function (_v, match: string) {
    return match
      .split(',')
      .map((v) => v.trim())
      .map((v) => {
        return `<sup>${v}</sup>`
      })
      .join('')
  })
}
