import type { BlockOptionDefinition } from '#blokkli/types/blokkOptions'

type GlobalOptionsDefaults = {
  type: BlockOptionDefinition['type']
  default: any
}

export const globalOptionsDefaults: Record<string, GlobalOptionsDefaults> = {
  "marginTop": {
    "default": "normal",
    "type": "radios"
  },
  "teaserColor": {
    "default": "BLUE",
    "type": "radios"
  }
} as const