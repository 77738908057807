<template>
  <div>
    <div v-if="numbers" class="highlights">
      <div class="highlights-first-row">
        <div class="highlights-digit">
          <span v-if="text.one" class="one">{{ text.one }}</span>
          {{ percentage }}%
        </div>
        <div class="highlights-first-text">
          {{ text.two }}
        </div>
        <div class="highlights-second-text">
          {{ text.three }}
        </div>
      </div>
      <div class="highlights-second-row">
        <div class="md:flex md:flex-col">
          <div class="highlights-digit">
            {{ count }}
          </div>
          <div class="highlights-second-text md:hidden">
            {{ text.four }}
          </div>
          <div class="highlights-first-text">
            {{ text.five }}
          </div>
        </div>
        <div class="highlights-second-text hidden md:block">
          {{ text.four }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineBlokkliFragment({
  name: 'number_highlights',
  label: 'Zahlen',
  description: 'Zeigt die Anzahl der Apotheken und Apotheker:innen an',
  editor: {
    previewWidth: 1200,
  },
})

const texts = {
  de: {
    one: '',
    two: 'der Schweizer Apotheken',
    three: 'und über',
    four: 'Apotheker & Apothekerinnen',
    five: 'vertrauen auf unsere Unterstützung bei der Transformation vom Fachhändler zum Leistungserbringer.',
  },
  fr: {
    one: '',
    two: 'des pharmacies suisses',
    three: 'et plus de',
    four: 'pharmaciens et pharmaciennes',
    five: 'font confiance à notre soutien pour la transformation de commerçant spécialisé en fournisseur de prestations.',
  },
  it: {
    one: `l'`,
    two: 'delle farmacie svizzere',
    three: 'e oltre',
    four: 'farmacisti',
    five: 'si affidano al nostro supporto nella trasformazione da rivenditore specializzato a fornitore di servizi.',
  },
}

const language = useCurrentLanguage()

const numbers = inject<ComputedRef<{
  percentage: number
  count: number
}> | null>('home_number_highlights', null)

const percentage = computed(() => numbers?.value.percentage)
const count = computed(() => numbers?.value.count)

const text = computed(() => {
  if (language.value === 'it') {
    return texts.it
  } else if (language.value === 'fr') {
    return texts.fr
  }
  return texts.de
})
</script>

<style lang="postcss">
.highlights {
  @apply w-full md:w-10/12 my-40 mx-auto;
}

.highlights-first-row {
  @apply flex flex-wrap md:flex-nowrap ml-30 md:ml-100;

  .highlights-digit {
    @apply text-green-light text-[100px] md:text-[150px] leading-tight md:leading-[0.8] font-bold md:mr-10;
  }

  .highlights-first-text {
    @apply text-lg md:text-2xl self-end md:mr-10 w-2/4 md:w-auto md:max-w-[200px] md:text-right ml-10 md:ml-0;
  }

  .highlights-second-text {
    @apply w-full md:w-auto text-sm md:text-md md:self-end ml-50 md:ml-0;
  }
}

.highlights-second-row {
  @apply flex flex-col md:flex-row mt-10 md:text-center;

  .highlights-digit {
    @apply text-green text-[100px] md:text-[150px] leading-none md:leading-[0.8] font-bold md:text-right;
  }

  .highlights-first-text {
    @apply text-sm md:text-md md:text-right order-last md:order-none text-right leading-none ml-auto mt-10 md:mt-0 w-4/5 md:w-9/12 self-end;
  }

  .highlights-second-text {
    @apply text-lg md:text-2xl text-left md:self-end md:ml-10 md:mb-20;
  }
}
</style>
