<template>
  <svg
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    stroke="currentColor"
    stroke-width="3"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="bg-white shadow-xl rounded text-green-alt transition-all"
    :class="{ 'scale-105 bg-green-alt': hover }"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
    @touchstart="hover = true"
    @touchend="hover = false"
  >
    <circle
      cx="50"
      cy="50"
      r="40"
      :stroke-dasharray="strokeDashArray"
      :stroke-dashoffset="strokeDashOffset"
      class="transition-all origin-center"
      :class="{ 'scale-90 text-green-alt': hover }"
    />
    <polygon points="40,40 60,50 40,60" />
    <polygon
      points="40,40 60,50 40,60"
      fill="white"
      class="transition-all origin-center"
      :class="{ 'scale-150 translate-x-3': hover }"
    />
  </svg>
</template>

<script lang="ts" setup>
const hover = ref(false)

const strokeDashOffset = computed(() => {
  return hover.value ? 0 : 19
})

const strokeDashArray = computed(() => {
  return hover.value ? '158 20' : '158 20'
})
</script>
