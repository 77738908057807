<template>
  <div
    :class="[
      margin,
      {
        'bk-paragraph-region-affected': options.isHidden,
      },
    ]"
    class="mb-10 md:mb-20"
  >
    <BlokkliField
      :list="paragraphs"
      non-empty-class="flex flex-wrap gap-10"
      name="field_paragraphs"
    />
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphContainerFragment } from '#graphql-operations'

const { options } = defineBlokkli({
  bundle: 'container',
  globalOptions: ['marginTop', 'isHidden'],
  editor: {
    disableEdit: true,
  },
})

defineProps<{
  paragraphs: ParagraphContainerFragment['paragraphs']
}>()

const margin = computed(() => {
  switch (options.value.marginTop) {
    case 'huge':
      return 'mt-40 lg:mt-50'
    case 'large':
      return 'mt-30 lg:mt-30'
    case 'normal':
      return 'mt-20'
  }
  return 'mt-10'
})
</script>
