<template>
  <nuxt-link
    v-if="url"
    :to="url.path"
    class="box box-padding min-h-200 md:min-h-min md:aspect-square relative hyphens-auto bg-pos flex items-end"
    :class="classes"
  >
    <h3
      class="font-semibold text-xl md:text-2xl lg:text-3xl xl:text-4xl relative z-10 mt-auto"
    >
      {{ teaserTitle || label }}
    </h3>
    <div
      v-if="icon"
      class="absolute right-0 top-0 bottom-0 left-0 z-0 opacity-30 flex items-center justify-end pr-20"
    >
      <MediaIcon v-bind="icon" class="w-[170px] lg:w-2/3 max-h-full" />
    </div>
  </nuxt-link>
</template>

<script lang="ts" setup>
import type {
  NodeTileFragment,
  NodeTilePageFragment,
  SiteColor,
} from '#graphql-operations'
import { getBoxClasses } from '~/helpers/siteColor'

const props = defineProps<{
  label?: NodeTileFragment['label']
  url?: NodeTileFragment['url']
  icon?: NodeTilePageFragment['icon']

  // Values not provided by fragment.
  color?: SiteColor
  teaserTitle?: String
}>()

const classes = computed(() => {
  return getBoxClasses(props.color)[0]
})
</script>

<style lang="postcss" scoped>
h3 {
  overflow-wrap: anywhere;
}
</style>
