<template>
  <div v-if="isPrint || isEditing" class="force-page-break">
    <div
      v-if="isEditing"
      class="relative grid grid-cols-[1fr_auto_1fr] items-center"
    >
      <div class="h-2 col-start-1 col-end-[-1] row-start-1 bg-green-alt" />
      <span
        class="col-start-2 row-start-1 bg-green-alt text-white px-10 uppercase text-xs font-medium rounded py-2"
        >Zeilenumbruch beim Drucken</span
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
const { isEditing } = defineBlokkliFragment({
  name: 'force_page_break',
  label: 'Zeilenumbruch beim Drucken',
  description: 'Damit kann beim Drucken ein Zeilenumbruch forciert werden.',
})

const { isPrint } = useViewport()
</script>

<style lang="postcss">
.force-page-break {
  page-break-before: always;
}
</style>
