<template>
  <ul class="md:flex md:gap-20">
    <li v-for="link in links">
      <nuxt-link
        :to="link?.uri?.path"
        class="text-gray-500 hover:text-gray-900 font-medium"
        >{{ getLinkLabel(link) }}</nuxt-link
      >
    </li>
  </ul>
</template>

<script lang="ts" setup>
import {
  FooterLinkFragment,
  ParagraphFooterSectionMetalinksFragment,
} from '#graphql-operations'

defineBlokkli({
  bundle: 'footer_section_metalinks',
})

defineProps<{
  links: ParagraphFooterSectionMetalinksFragment['links']
}>()

function getLinkLabel(link: FooterLinkFragment): string {
  if (link.title) {
    return link.title
  }
  if (link.uri && 'entity' in link.uri) {
    return link?.uri?.entity?.label || ''
  }
  return ''
}
</script>
