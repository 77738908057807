/**
 * A split title.
 */
type SplitTitle = {
  top: string
  bottom?: string
}

/**
 * Split the given string at the given word count.
 *
 * @example
 * splitTitle('Lorem ipsum dolor sit amet', 3)
 * { top: 'Lorem ipsum dolor', bottom: 'sit amet' }
 */
export function splitTitle(title = '', words = 0): SplitTitle {
  const rgx = new RegExp(`(([^\\s]*\\s){${words}})(.*)`)
  const split = title.match(rgx)
  if (!split) {
    return { top: title }
  }
  const top = split[1]
  const bottom = split[3]
  return {
    top,
    bottom,
  }
}
