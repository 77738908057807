<template>
  <div v-if="config && config.address">
    <address class="not-italic mb-15">
      <p class="text-[22px] font-medium mb-20 pr-20">
        {{ config.address.organization }}
      </p>
      <p>
        {{ config.address.addressLine1 }}<br />{{ config.address.postalCode }}
        {{ config.address.locality }}<br />
        {{ $texts('switzerland', 'Schweiz') }}
      </p>
    </address>
    <ul>
      <li v-for="link in config.links" class="mb-10">
        <nuxt-link :to="link.uri?.path" class="footer-link">
          <SpriteSymbol name="arrow" class="w-20 h-10 mr-5" />
          <span>{{ link.title }}</span>
        </nuxt-link>
      </li>
    </ul>
    <p>
      <a :href="`tel:` + config.phone" class="footer-link">{{
        config.phone
      }}</a>
    </p>
    <p>
      <a :href="`mailto:` + config.email" class="footer-link">{{
        config.email
      }}</a>
    </p>
  </div>
</template>

<script lang="ts" setup>
import type { ParagraphFooterSectionContactDetailsFragment } from '#graphql-operations'

defineBlokkli({
  bundle: 'footer_section_contact_details',
})

defineProps<{
  title?: string
  config?: ParagraphFooterSectionContactDetailsFragment['config']
}>()
</script>
