<template>
  <svg
    viewBox="0 0 1170 370"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g>
      <rect
        x="-95.418"
        y="-235"
        width="1906.07"
        height="1769.08"
        transform="rotate(21.8559 -95.418 -235)"
        fill="url(#paint1_linear_4047_15137)"
      />
      <path
        opacity="0.3"
        d="M861.986 -388.507C860.101 -644.931 633.356 -823 376.463 -823H227.604V-175.422H-452V472H17.1909C45.1414 352.031 179.712 199.872 399.861 99.8463C648.746 -13.213 864.027 -122.66 861.986 -388.507Z"
        fill="url(#paint2_linear_4047_15137)"
        stroke="white"
        stroke-width="0.5"
      />
      <path
        opacity="0.3"
        d="M981.753 -174C953.799 -53.8745 819.213 97.9706 599.038 198.154C350.28 311.213 134.973 420.661 137.014 686.507C138.899 942.932 365.671 1121 622.437 1121H771.314V473.422H1451V-174H981.753Z"
        fill="url(#paint3_linear_4047_15137)"
        stroke="white"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <linearGradient
        id="paint1_linear_4047_15137"
        x1="1569.02"
        y1="4.90202"
        x2="87.3525"
        y2="177.412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#178ED3" />
        <stop offset="1" stop-color="#178ED3" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_4047_15137"
        x1="205"
        y1="-823"
        x2="205"
        y2="472"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_4047_15137"
        x1="794"
        y1="-174"
        x2="794"
        y2="1121"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>
