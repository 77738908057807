<template>
  <div
    class="my-20 lg:my-50"
    :class="{
      'bk-paragraph-region-affected': options.isHidden,
    }"
  >
    <iframe
      ref="iframe"
      :src="src"
      :class="{ 'pointer-events-none': isEditing }"
      class="w-full min-h-300"
      @load="onLoad"
    />
  </div>
</template>

<script lang="ts" setup>
import { iframeResizer } from 'iframe-resizer'

const { isEditing, options } = defineBlokkli({
  bundle: 'mailxpert',
  globalOptions: ['isHidden'],
})

const props = defineProps<{
  mailxpertId?: string
}>()

const iframe = ref<HTMLIFrameElement | null>(null)
const language = useCurrentLanguage()

const onLoad = () => {
  if (iframe.value) {
    iframeResizer(
      {
        autoResize: true,
      },
      iframe.value,
    )
  }
}

const src = computed(
  () =>
    `https://mail.pharmasuisse.org/e/81af2194a2aa7468/${language.value}/form/${props.mailxpertId}.html?render=container`,
)

onBeforeUnmount(() => {
  if (iframe.value) {
    const i = iframe.value as any
    if (i.iFrameResizer) {
      i.iFrameResizer.removeListeners()
    }
  }
})
</script>
